import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { AlumnoProvider } from './context/alumnos/AlumnosContext'
import { GlobalProvider } from './context/global/GlobalContext'
import { UserProvider } from './context/users/UserContext'
import './index.css'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<GlobalProvider>
			<UserProvider>
				<AlumnoProvider>
					<App />
				</AlumnoProvider>
			</UserProvider>
		</GlobalProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
