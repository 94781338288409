import React from 'react'

export default function Avatar({ width, src, alt, className }) {
	return <img style={avatar} width={width} className={className} src={src} alt={alt} />
}
const avatar = {
	border: '1px solid black',
	borderRadius: '8px',
	marginRight: '20px',
}
