import React, { useContext } from 'react';
import { AlumnosContext } from '../../context/alumnos/AlumnosContext';
import Formulario from './ABM/Formulario';
import { Container } from '../../assets/styled';

export default function NuevoAlumno() {
	const alumnoCtx = useContext(AlumnosContext);

	const { createAlumno, data } = alumnoCtx;

	const sendData = (event) => {
		event.preventDefault();
		createAlumno(data);
	};
	return (
		<Container>
			<Formulario titulo='Crear nuevo alumno' handleSubmit={sendData} />
		</Container>
	);
}
