import React, { createContext, useEffect, useState } from 'react';
import clienteAxios from '../../config/axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState({ username: null, email: null });
	const [authStatus, setAuthStatus] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [data, setData] = useState({
		email: '',
		password: '',
	});

	useEffect(() => {
		if (user && user.isAdmin) setIsAdmin(user.isAdmin);
		else setIsAdmin(false);
	}, [user]);

	const handleChange = (event) => {
		event.preventDefault();
		setData({
			...data,
			[event.target.name]: event.target.value,
		});
	};

	const registerUser = async (dataForm) => {
		try {
			const res = await clienteAxios.post('/usuarios/crear', dataForm);
			localStorage.setItem('token', res.data.token);
			setAuthStatus(true);
		} catch (error) {
			console.error(error);
		}
	};

	const verifyingToken = async () => {
		const token = localStorage.getItem('token');

		if (token) {
			clienteAxios.defaults.headers.common['x-auth-token'] = token;
		} else {
			delete clienteAxios.defaults.headers.common['x-auth-token'];
		}

		try {
			const respuesta =
				token && (await clienteAxios.get('/usuarios/verificar'));
			setUser(respuesta.data.usuario);
			setAuthStatus(true);
		} catch (error) {
			console.error('Error Verificando token', error);
		}
	};

	const loginUser = async (dataForm) => {
		try {
			const respuesta = await clienteAxios.post('/usuarios/login', dataForm);
			localStorage.setItem('token', respuesta.data.token);
			setAuthStatus(true);
			alert('Usuario Logeado');
		} catch (error) {
			console.error(error);
		}
	};

	const logout = () => {
		localStorage.removeItem('token');
		setUser(null);
		setAuthStatus(false);
	};

	const contextData = {
		user,
		authStatus,
		registerUser,
		loginUser,
		handleChange,
		verifyingToken,
		logout,
		data,
		isAdmin,
	};
	// console.log('UserContext :', contextData)
	return (
		<UserContext.Provider value={contextData}>{children}</UserContext.Provider>
	);
};
