import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import AlumnoProfile from './components/Alumnos/AlumnoProfile'
import Asistencia from './components/Alumnos/Asistencia/Asistencia'
import NuevoAlumno from './components/Alumnos/NuevoAlumno'
import Pagos from './components/Alumnos/Pagos/Pagos'
import PrivateRoute from './components/Auth/PrivateRoute'
import Credencial from './components/Home/Credencial'
import Home from './components/Home/Home'
import Header from './components/Layout/Header'
import Login from './components/Login/Login'
import Register from './components/Register/Register'
import { UserContext } from './context/users/UserContext'

function App() {
	const { isAdmin } = useContext(UserContext)
	return (
		<>
			<Router>
				<Header />
				<Routes>
					{/* RUTAS PRIVADAS */}
					<Route element={<PrivateRoute />}>
						<Route path='/nuevo-alumno' element={<NuevoAlumno />} />
						<Route path='/asistencia' element={<Asistencia />} />
						{isAdmin && (
							<>
								<Route path='/pagos' element={<Pagos />} />
								<Route path='/alumno/:id' element={<AlumnoProfile />} />
							</>
						)}
					</Route>
					{/* RUTAS DE AUTENTICACIÓN */}
					<Route path='/registro' element={<Register />} />
					<Route path='/iniciar-sesion' element={<Login />} />

					{/* RUTAS PÚBLICAS */}
					<Route path='/' element={<Home />} />
					<Route path='/:dni' element={<Credencial />} />
				</Routes>
			</Router>
		</>
	)
}

export default App
