import React from 'react'
import { HomeContainer } from '../../assets/styled'
import MainLogo from '../MainLogo'
import Search from './Search'

export default function Home() {
	return (
		<HomeContainer>
			<br />
			<MainLogo />
			<Search />
		</HomeContainer>
	)
}
