import React, { useContext } from 'react'
import { Nav, NavLink } from '../../assets/styled'
import { UserContext } from '../../context/users/UserContext'

export default function Header() {
	const ctx = useContext(UserContext)

	const { logout, authStatus, isAdmin } = ctx

	return (
		<div>
			{authStatus ? (
				<Nav>
					<NavLink to='/nuevo-alumno'>Nuevo Alumno</NavLink>
					<NavLink to='/asistencia'>Asistencia</NavLink>
					{isAdmin ? <NavLink to='/pagos'>Pagos</NavLink> : null}
					<NavLink to='/' onClick={logout}>
						Cerrar sesión
					</NavLink>
				</Nav>
			) : (
				<Nav>
					<NavLink to='/iniciar-sesion'>Iniciar sesión</NavLink>
				</Nav>
			)}
		</div>
	)
}
