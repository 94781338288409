import moment from 'moment';
import React, { createContext, useState } from 'react';
import clienteAxios from '../../config/axios';

export const AlumnosContext = createContext();

export const AlumnoProvider = ({ children }) => {
	const [alumnos, setAlumnos] = useState([]);
	const [credencialAlumno, setCredencialAlumno] = useState();

	const [data, setData] = useState({
		nombre: '',
		apellido: '',
		dni: '',
		categoria: 'INFANTIL',
		fechaNacimiento: '',
	});

	const handleFaltas = (id) => {
		let update = alumnos.find((e) => {
			return e.id === id;
		});
		let updateData = {
			...update,
			asistencia: new Date().toLocaleDateString(),
		};
		asistencia(id, updateData);
	};
	const handlePagos = (id, pago, mesPago) => {
		let update = alumnos.find((e) => {
			return e.id === id;
		});
		let updateData = {
			...update,
			pagos: {
				monto: +pago,
				fecha: new Date().toLocaleDateString(),
				mesPago: mesPago || 'ENERO',
			},
			estado: 'pago',
		};
		pagos(id, updateData);
	};

	const handleResetPagos = () => {
		let monthData = {
			month: moment(new Date()).format('M'),
			year: moment(new Date()).format('YYYY'),
		};
		const currentMonth = JSON.parse(localStorage.getItem('currentMonth'));

		if (!currentMonth) {
			localStorage.setItem('currentMonth', JSON.stringify(monthData));
		} else if (currentMonth && currentMonth.year === monthData.year) {
			alumnos.forEach((alumno) => {
				let pagosArray = [];
				alumno.pagos.forEach((pago) => {
					const mesPago = parseInt(pago.fecha.split('/')[1]);
					pagosArray.push(mesPago);
				});
				if (!pagosArray.includes(currentMonth.month)) {
					resetPagos();
				}
			});
			localStorage.setItem('currentMonth', JSON.stringify(monthData));
		}
	};

	const handleChangeAlumno = (event) => {
		console.log('event :', event);
		event.preventDefault();
		setData({
			...data,
			[event.target.name]: event.target.value,
		});
	};

	const createAlumno = async (dataForm) => {
		const form = {
			nombre: dataForm.nombre,
			apellido: dataForm.apellido,
			dni: dataForm.dni,
			categoria: dataForm.categoria,
			fechaNacimiento: dataForm.fechaNacimiento,
			estado: 'debe',
			avatar:
				'https://pbs.twimg.com/profile_images/1445078912963420162/UruNaQPN_400x400.jpg',
			asistencia: [],
		};

		try {
			await clienteAxios.post(`/alumnos/crear`, form);
			alert('Alumno Creado');
			getAlumnos();
			setData({
				nombre: '',
				apellido: '',
				dni: '',
				categoria: 'INFANTIL',
				fechaNacimiento: '',
			});
		} catch (error) {
			console.error(error);
		}
	};

	const getAlumnos = async () => {
		try {
			const res = await clienteAxios.get(`/alumnos/obtener`);

			const alumnos = res.data.Alumnos.map((e) => {
				return {
					id: e._id,
					nombre: e.nombre,
					apellido: e.apellido,
					dni: e.dni,
					categoria: e.categoria,
					estado: e.estado,
					fechaNacimiento: e.fechaNacimiento,
					avatar: e.avatar,
					asistencia: e.asistencia,
					pagos: e.pagos,
				};
			});
			setAlumnos(alumnos);
		} catch (error) {
			console.error(error);
		}
	};
	const getCredencial = async (dni) => {
		try {
			const res = await clienteAxios.get(`/alumnos/credencial?dni=${dni}`);
			setCredencialAlumno(res.data.credencial[0]);
		} catch (error) {
			console.error(error);
		}
	};

	const updateAlumno = async (id, dataForm) => {
		const form = {
			id,
			nombre: dataForm.nombre,
			apellido: dataForm.apellido,
			dni: dataForm.dni,
			categoria: dataForm.categoria,
			estado: dataForm.estado,
			fechaNacimiento: dataForm.fechaNacimiento,
			avatar: dataForm.avatar,
			asistencia: dataForm.asistencia,
		};

		try {
			await clienteAxios.put(`/alumnos/actualizar`, form);
			getAlumnos();
		} catch (error) {
			console.error(error);
		}
	};

	const deleteAlumno = async (id) => {
		const data = { id };

		try {
			await clienteAxios.delete(`/alumnos/borrar`, { data });
			getAlumnos();
		} catch (error) {
			console.error(error);
		}
	};

	const asistencia = async (id, dataForm) => {
		const form = {
			id,
			asistencia: dataForm.asistencia,
		};
		try {
			await clienteAxios.put(`/alumnos/asistencia`, form);
			getAlumnos();
		} catch (error) {
			console.error(error);
		}
	};
	const pagos = async (id, dataForm) => {
		const form = {
			id,
			pagos: dataForm.pagos,
			estado: dataForm.estado,
		};
		try {
			await clienteAxios.put(`/alumnos/pagos`, form);
			alert('Pago Realizado');
			getAlumnos();
		} catch (error) {
			console.error(error);
		}
	};
	const resetPagos = async () => {
		const form = {
			estado: 'debe',
		};
		try {
			await clienteAxios.put(`/alumnos/reset-pagos`, form);
			getAlumnos();
		} catch (error) {
			console.error(error);
		}
	};

	const contextData = {
		alumnos,
		setAlumnos,
		data,
		setData,
		handleChangeAlumno,
		createAlumno,
		getAlumnos,
		updateAlumno,
		deleteAlumno,
		handleFaltas,
		asistencia,
		handlePagos,
		handleResetPagos,
		getCredencial,
		credencialAlumno,
	};
	//console.log('AlumnosContext :', contextData);
	return (
		<AlumnosContext.Provider value={contextData}>
			{children}
		</AlumnosContext.Provider>
	);
};
