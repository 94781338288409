import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AlumnosContext } from '../../context/alumnos/AlumnosContext';
import ActionsDropdown from '../Alumnos/ActionsDropdown/ActionsDropdown';
import Botonera from '../Alumnos/Botonera/Botonera';
import Avatar from './Avatar';

export default function Tabla({
	data,
	columns,
	options,
	asistencia,
	pagos,
	ficha,
	alumno,
}) {
	const alumnoCtx = useContext(AlumnosContext);
	const { updateAlumno, deleteAlumno, handlePagos } = alumnoCtx;
	const [filtroCategoria, setFiltroCategoria] = useState('');
	const [filtroNombre, setFiltroNombre] = useState('');
	const [filtroApellido, setFiltroApellido] = useState('');
	const [filtroClase, setFiltroClase] = useState('');
	const [montos, setMontos] = useState({});

	const functions = { updateAlumno, deleteAlumno };

	const handleFilterCategoriaChange = (e) => {
		setFiltroCategoria(e.target.value);
	};

	const handleFilterNombreChange = (e) => {
		setFiltroNombre(e.target.value);
	};

	const handleFilterApellidoChange = (e) => {
		setFiltroApellido(e.target.value);
	};
	const handleFilterClaseChange = (e) => {
		setFiltroClase(e.target.value);
	};

	const pagosYAsistencias = () => {
		const datosFiltrados = data.filter((e) => {
			let clase = new Date(e.fechaNacimiento).getFullYear();
			return (
				e.categoria.toLowerCase().includes(filtroCategoria.toLowerCase()) &&
				e.nombre.toLowerCase().includes(filtroNombre.toLowerCase()) &&
				e.apellido.toLowerCase().includes(filtroApellido.toLowerCase()) &&
				String(clase).toLowerCase().includes(filtroClase.toLowerCase())
			);
		});
		return datosFiltrados.map((e) => {
			// const badge = () => {

			//   return e.estado === "pago" ? (
			//     <span className="badge text-bg-success">
			//       {e.estado.toUpperCase()}
			//     </span>
			//   ) : (
			//     <span className="badge text-bg-danger">
			//       {e.estado.toUpperCase()}
			//     </span>
			//   );
			// };

			const handleRowColor = () => {
				return e.asistencia.includes(new Date().toLocaleDateString())
					? '#b1d7c0'
					: '#d7a2a2';
			};
			return (
				<tr
					key={e.id}
					style={{
						backgroundColor: asistencia ? handleRowColor() : '#FFFFFF',
					}}
				>
					<td>
						<Avatar
							width='40px'
							src={e.avatar}
							alt={`avatar ${e.nombre} ${e.apellido}`}
						/>
						<Link to={`/alumno/${e.id}`}>
							{e.nombre} {e.apellido}
						</Link>
					</td>
					<td>{e.categoria}</td>
					{/* <td>{badge()}</td> */}
					<td>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-evenly',
							}}
						>
							<Botonera asistencia={asistencia} pagos={pagos} id={e.id} />
							{options && <ActionsDropdown functions={functions} id={e.id} />}
						</div>
					</td>
				</tr>
			);
		});
	};
	const tablaFicha = () => {
		const handleMontoChange = (mes, valor) => {
			setMontos((prev) => ({ ...prev, [mes]: valor }));
		};
		const celdas = columns.map((mes, idx) => {
			return (
				<td key={idx}>
					<div className='d-flex flex-column align-items-center'>
						<input
							className='inputFicha'
							type='text'
							value={montos[mes.text] || ''}
							onChange={(e) => handleMontoChange(mes.text, e.target.value)}
							onClick={(e) => e.target.select()}
						/>
						<button
							className='btn btn-success mt-1'
							onClick={() => handlePagos(alumno.id, montos[mes.text], mes.text)}
						>
							GUARDAR
						</button>
					</div>
				</td>
			);
		});

		return <tr>{celdas}</tr>;
	};

	useEffect(() => {
		const montosIniciales = {};
		ficha &&
			alumno.pagos.forEach((pago) => {
				montosIniciales[pago.mesPago] = pago.monto;
			});
		setMontos(montosIniciales);
	}, [alumno]);

	return (
		<div style={{ margin: '10px' }}>
			{!ficha && (
				<>
					<input
						type='text'
						value={filtroCategoria}
						onChange={handleFilterCategoriaChange}
						placeholder='Filtrar por categoría'
					/>
					<input
						type='text'
						value={filtroNombre}
						onChange={handleFilterNombreChange}
						placeholder='Filtrar por nombre'
					/>
					<input
						type='text'
						value={filtroApellido}
						onChange={handleFilterApellidoChange}
						placeholder='Filtrar por apellido'
					/>
					<input
						type='text'
						value={filtroClase}
						onChange={handleFilterClaseChange}
						placeholder='Filtrar por clase'
					/>
				</>
			)}
			<table className='table table-striped fluid'>
				<thead>
					<tr>
						{columns.map((e) => {
							return (
								<th key={e.text} scope='col'>
									{e.text}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody>{!ficha ? pagosYAsistencias() : tablaFicha()}</tbody>
			</table>
		</div>
	);
}
