import React, { useContext } from 'react';
import FormInput from '../../FormInput';
import { AlumnosContext } from '../../../context/alumnos/AlumnosContext';
import { UserContext } from '../../../context/users/UserContext';

export default function Formulario({ titulo, handleSubmit }) {
	const alumnoCtx = useContext(AlumnosContext);

	const userCtx = useContext(UserContext);

	const { handleChange, authStatus } = userCtx;
	const { handleChangeAlumno, data } = alumnoCtx;
	return (
		<div style={{ width: '300px' }}>
			<h2 style={title}>{titulo}</h2>
			<form onSubmit={(e) => handleSubmit(e)}>
				<FormInput tipo='nombre' />
				<FormInput tipo='apellido' />
				<FormInput tipo='dni' />
				<div className='mt-4  column'>
					<label>CATEGORIA</label>
					<select
						className='mt-2 form-select'
						name='categoria'
						value={data.categoria}
						onChange={(e) => {
							handleChangeAlumno(e);
						}}
					>
						<option value='INFANTIL'>INFANTIL</option>
						<option value='JUVENIL'>JUVENIL</option>
						<option value='FEMENINO'>FEMENINO</option>
						<option value='MAYORES'>MAYORES</option>
						<option value='HOCKEY'>HOCKEY</option>
					</select>
				</div>
				<FormInput tipo='fechaNacimiento' />
				<div className='d-grid gap-2 mt-4'>
					<button className='btn btn-success fluid' type='submit'>
						Crear
					</button>
				</div>
			</form>
		</div>
	);
}
const title = { display: 'flex', justifyContent: 'center' };
