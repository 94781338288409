import React, { useContext } from 'react';
import { AlumnosContext } from '../context/alumnos/AlumnosContext';
import { UserContext } from '../context/users/UserContext';
import { useLocation } from 'react-router-dom';

export default function FormInput({ tipo, noLabel }) {
	const alumnoCtx = useContext(AlumnosContext);
	const location = useLocation();
	const userCtx = useContext(UserContext);

	const { handleChange, authStatus } = userCtx;
	const { handleChangeAlumno, data } = alumnoCtx;

	const options = {
		username: { titulo: 'Nombre de usuario', type: 'text' },
		email: { titulo: 'Direccion de E-Mail', type: 'mail' },
		password: { titulo: 'Contraseña', type: 'password' },
		nombre: { titulo: 'Nombre', type: 'text' },
		apellido: { titulo: 'Apellido', type: 'text' },
		dni: { titulo: 'DNI', type: 'number' },
		categoria: { titulo: 'Categoria', type: 'string' },
		fechaNacimiento: { titulo: 'Fecha de Nacimiento', type: 'date' },
		pago: { titulo: 'Pago', type: 'number' },
	};

	return (
		<>
			<div className={`${!noLabel && 'mt-4'} form-input-div`}>
				{!noLabel && (
					<label for={tipo} className='form-label'>
						{options[tipo].titulo}
					</label>
				)}
				<input
					className='form-control'
					placeholder={options[tipo].titulo}
					id={tipo}
					name={tipo}
					type={options[tipo].type}
					required
					value={!location.pathname.includes('/pagos') ? data[tipo] : undefined}
					onChange={(e) => {
						authStatus ? handleChangeAlumno(e) : handleChange(e);
					}}
				/>
			</div>
		</>
	);
}
