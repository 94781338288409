import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AlumnoData } from '../../assets/styled';
import { AlumnosContext } from '../../context/alumnos/AlumnosContext';
import Calendario from '../Calendario';
import Tabla from '../Tabla/Tabla';

function AlumnoProfile() {
	const dataAlumno = useContext(AlumnosContext);
	const { id } = useParams('id');
	const alumno = dataAlumno.alumnos.find((alumno) => alumno.id === id);

	const columns = [
		{ text: 'ENERO' },
		{ text: 'FEBRERO' },
		{ text: 'MARZO' },
		{ text: 'ABRIL' },
		{ text: 'MAYO' },
		{ text: 'JUNIO' },
		{ text: 'JULIO' },
		{ text: 'AGOSTO' },
		{ text: 'SEPTIEMBRE' },
		{ text: 'OCTUBRE' },
		{ text: 'NOVIEMBRE' },
		{ text: 'DICIEMBRE' },
	];

	return (
		<div
			style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
		>
			<br />
			<br />
			<div>
				<AlumnoData color='#000'>{`Alumno: ${alumno.nombre} ${alumno.apellido}`}</AlumnoData>
				<AlumnoData color='#000'>{`Categoria ${alumno.categoria}`}</AlumnoData>
			</div>
			{/* //esto es para el calendario de asistencia */}
			{/* <Calendario month={'mayo'} startDate='1' data={alumno.asistencia} /> */}
			<Link to={`/${alumno.dni}`}>Ver Credencial de Pagos</Link>
			<Tabla alumno={alumno} columns={columns} ficha />
		</div>
	);
}

export default AlumnoProfile;
