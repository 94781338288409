import React, { useContext, useEffect } from 'react'
import { AlumnosContext } from '../../../context/alumnos/AlumnosContext'
import Tabla from './../../Tabla/Tabla'

export default function Asistencia() {
	const alumnoCtx = useContext(AlumnosContext)
	const { getAlumnos, alumnos } = alumnoCtx

	const columns = [{ text: 'Nombre' }, { text: 'Cat.' }, { text: 'Estado' }, { text: '' }]

	useEffect(() => {
		getAlumnos()
	}, [])

	return <Tabla data={alumnos} columns={columns} asistencia />
}
