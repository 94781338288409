import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
	AlumnoData,
	Button,
	CredencialCard,
	CredentialsContainer,
} from '../../assets/styled';
import { AlumnosContext } from '../../context/alumnos/AlumnosContext';
import { arrayMeses } from '../../utilities/utils';
import MainLogo from '../MainLogo';

export default function Credencial() {
	const alumnoCtx = useContext(AlumnosContext);
	const { getCredencial, credencialAlumno } = alumnoCtx;
	const dni = useParams().dni;
	const currentYear = new Date().getFullYear();

	const navigate = useNavigate();

	const Header = () => {
		return (
			<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
				<AlumnoData>{`Alumno: ${credencialAlumno.nombre} ${credencialAlumno.apellido}`}</AlumnoData>
				<AlumnoData>{`Categoria ${credencialAlumno.categoria}`}</AlumnoData>
				<AlumnoData>Año: {currentYear}</AlumnoData>
			</div>
		);
	};
	const MonthCard = ({ month, index }) => {
		let result = credencialAlumno.pagos.filter((e) => {
			return arrayMeses.indexOf(e?.mesPago?.toUpperCase()) === index;
		});
		const paymentYear = 2024;
		const isCurrentYearPayment = currentYear === paymentYear;
		return (
			<>
				{isCurrentYearPayment ? (
					<CredencialCard>
						<div>{month}</div>
						<div>{result[0] ? result[0].fecha : ''}</div>
						<div>{result[0] ? `$${result[0].monto}` : ''}</div>
					</CredencialCard>
				) : (
					<CredencialCard>
						<div>{month}</div>
					</CredencialCard>
				)}
			</>
		);
	};

	useEffect(() => {
		getCredencial(dni);
	}, []);
	return (
		<div className='flexContainer'>
			<CredentialsContainer>
				<MainLogo />
				<Button onClick={() => navigate(-1)}>Volver</Button>
				{credencialAlumno && (
					<>
						<Header />
						{arrayMeses.map((e, i) => {
							return <MonthCard key={i} month={e} index={i} />;
						})}
					</>
				)}
			</CredentialsContainer>
		</div>
	);
}
