import React, { useContext, useEffect } from 'react';
import { AlumnosContext } from '../../../context/alumnos/AlumnosContext';
import Tabla from './../../Tabla/Tabla';

export default function Pagos() {
	const alumnoCtx = useContext(AlumnosContext);
	const { getAlumnos, alumnos, handleResetPagos } = alumnoCtx;

	const columns = [
		{ text: 'Nombre' },
		{ text: 'Categoria' },
		// { text: 'Estado' },
		{ text: '' },
	];

	useEffect(() => {
		// handleResetPagos();
		getAlumnos();
	}, []);

	return <Tabla data={alumnos} columns={columns} pagos options />;
}
