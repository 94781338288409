import React, { createContext } from 'react'

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
	const mobileSize = () => window.screen.width < 470

	const isMobile = mobileSize()

	const contextData = {
		isMobile,
	}
	// console.log('GlobalContext :', contextData)
	return <GlobalContext.Provider value={contextData}>{children}</GlobalContext.Provider>
}
