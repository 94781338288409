import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import FormInput from '../FormInput'
import { UserContext } from './../../context/users/UserContext'

export default function Login() {
	const userCtx = useContext(UserContext)
	const { loginUser, authStatus, verifyingToken, data } = userCtx

	const navigate = useNavigate()

	useEffect(() => {
		verifyingToken()

		if (authStatus) {
			navigate('/asistencia')
		}
	}, [authStatus])

	if (authStatus) return null

	const sendData = (event) => {
		event.preventDefault()
		loginUser(data)
	}

	return (
		<>
			<h2 className='title'>Iniciar sesión</h2>

			<form onSubmit={(e) => sendData(e)} className='form'>
				<FormInput tipo='email' />
				<FormInput tipo='password' />
				<button type='submit' className='form-btn'>Ingresar</button>
			</form>
		</>
	)
}
