import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import fondo from './fondo.jpg';

export const CredencialCard = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: rgba(255, 255, 255, 0.9);
	border: 2px solid black;
	border-radius: 8px;
	margin: 10px;
	padding: 10px;
`;
export const CredentialsContainer = styled.div`
	width: 90%;
	margin: 20px auto;
	border: 2px solid black;
	border-radius: 8px;
	background-image: url(${fondo});
	background-size: cover;
`;
export const Button = styled.button`
	padding: 3px 5px;
	margin-left: 15px;
`;
export const AlumnoData = styled.p`
	text-align: center;
	font-weight: 700;
	color: ${({ color }) => (color ? color : '#ffffff')};
`;
export const Img = styled.img`
	width: ${({ width }) => (width ? width : '')};
	height: ${({ height }) => (height ? height : '')};
`;
export const Text = styled.p`
	margin: 0px;
	font-size: ${({ size }) => (size ? size : '14px')};
	text-align: center;
	color: #ffffff;
`;

export const MainLogoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const HomeContainer = styled.div`
	width: 100%;
	min-height: 900px;
	background-image: url(${fondo});
	background-size: cover;
	background-position: center;
`;

export const Nav = styled.nav`
	height: 40px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	background-color: #000000;
`;
export const NavLink = styled(Link)`
	color: #ffffff;
	text-decoration: none;
	&:hover {
		color: green;
	}
`;
export const CalendarContainer = styled.div`
	text-align: center;
	width: 400px;
`;
export const CalendarList = styled.ol`
	list-style: none;
	border: 1px solid black;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	padding: 10px;
`;
export const CalendarHeader = styled.li`
	background-color: #eee;
	font-weight: bold;
`;
export const CalendarItem = styled.li`
	padding: 5px 0px;
`;
export const Container = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 30px;
	marging-top: 30px;
`;
