import React from 'react'
import { DeleteDocumentIcon } from './DeleteDocumentIcon.js'
import { EditDocumentIcon } from './EditDocumentIcon.js'

export default function ActionsDropdown({ functions, id }) {
	const menuItems = [
		{ key: 'edit', name: 'Editar Alumno', icon: <EditDocumentIcon size={22} /> },
		{ key: 'delete', name: 'Borrar Alumno', icon: <DeleteDocumentIcon size={22} /> },
	]

	const handleChange = (item) => {
		item.key === 'delete' && functions.deleteAlumno(id)
		item.key === 'edit' && console.log('edit Alumno', id)
	}
	return (
		<div className='dropdown hideOnSmall'>
			<button className='btn btn-secondary dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
				Más
			</button>
			<ul className='dropdown-menu'>
				{menuItems.map((item, index) => {
					return (
						<li key={index}>
							<button key={item.key} className='dropdown-item' onClick={() => handleChange(item)}>
								{item.icon}
								{item.name}
							</button>
						</li>
					)
				})}
			</ul>
		</div>
	)
}
