import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function Search() {
	const [inputValue, setInputValue] = useState()
	let navigate = useNavigate()

	return (
		<div className='search'>
			<input className='form-control' style={{ marginRight: '20px' }} placeholder='Ingresar DNI' type='number' onChange={(e) => setInputValue(e.target.value)} />
			<br />
			<button onClick={() => navigate(`/${inputValue}`)} className=' btn btn-warning mr-2'>
				Ver Carnet
			</button>
		</div>
	)
}

export default Search
