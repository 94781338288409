import React, { useContext } from 'react'
import logo from '../assets/logo.png'
import nombre from '../assets/nombre.png'
import { Img, MainLogoContainer } from '../assets/styled'
import { GlobalContext } from '../context/global/GlobalContext'

function MainLogo() {
	const globalCtx = useContext(GlobalContext)
	const { isMobile } = globalCtx

	return (
		<MainLogoContainer>
			<Img width={isMobile ? '100px' : '150px'} height={isMobile ? '100px' : '150px'} src={logo} alt='logo mammana' />
			<Img width={isMobile ? '150px' : '250px'} src={nombre} alt='nombre mammana'></Img>
		</MainLogoContainer>
	)
}

export default MainLogo
